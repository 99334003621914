import React, { useEffect } from "react";
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PopupButton } from '@typeform/embed-react';
import Slider from "react-slick";

function Studio() {
      // const currentYear = moment().year();

      useEffect(() => {
            AOS.init();
      }, []);

      var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 660,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };


      return (
            <div className="App">
                  <nav className="navbar navbar-expand-lg fixed-top">
                        <div className="container-fluid">
                              <a className="navbar-brand" href="#"><img src={'/assets/images/logoaw.png'} alt="" className="mw-100" /> Studio</a>
                              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                              </button>
                              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                                          <li className="nav-item dropdown">
                                                <a className="nav-link" href="#about">
                                                      About Us
                                                </a>
                                          </li>
                                          <li className="nav-item">
                                                <a className="nav-link active" aria-current="page" href="#partner">Partners</a>
                                          </li>
                                          <li className="nav-item">
                                                <PopupButton id="QTZzFXnk" className="trasbtn"><span>Contact Us</span></PopupButton>
                                          </li>
                                    </ul>

                              </div>
                        </div>
                  </nav>

                  <div className="dasbanner">
                        <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-bs-ride="carousel">
                              <div className="carousel-inner">
                                    <div className="carousel-item active">
                                          <img src="/assets/images/das-banner-bg.jpg" className="d-block w-100" alt="..." />
                                          <div className="carousel-caption">
                                                <img src={'/assets/images/d-3d-logo.png'} alt="" className="mw-100" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500" />
                                                <h1 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">Where Creativity, <br />technology, and consumer <br />behavior meet in Web3.</h1>
                                          </div>

                                    </div>
                              </div>
                        </div>
                        <div className="m_icons">
                              <div className="m_icon _m1">
                                    <img src="/assets/images/m_icon1.png" alt="" />
                              </div>
                              <div className="m_icon _m2">
                                    <img src="/assets/images/m_icon2.png" alt="" />
                              </div>
                              <div className="m_icon _m3">
                                    <img src="/assets/images/m_icon3.png" alt="" />
                              </div>
                              <div className="m_icon _m4">
                                    <img src="/assets/images/m_icon4.png" alt="" />
                              </div>
                              <div className="m_icon _m5">
                                    <img src="/assets/images/m_icon5.png" alt="" />
                              </div>
                        </div>
                  </div>

                  <div className="about_us" id="about">
                        <div className="container">
                              <Accordion defaultActiveKey="0" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                                    <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                                About us
                                                <span></span>
                                          </Accordion.Header>
                                          <Accordion.Body>At  Disrupt Studios we make NFTs accessible and secure so creators, brands, and nonprofit organizations can connect with their customers through web3 experiences.</Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                                EASE OF USE
                                                <span></span>
                                          </Accordion.Header>
                                          <Accordion.Body>Your website is white labeled, with a custom storefront design and credit card payment integrations. Our platform delivers the most user-friendly NFT experiences for brands, creators, and nonprofits. </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                          <Accordion.Header>
                                                Activate and Empower!
                                                <span></span>
                                          </Accordion.Header>
                                          <Accordion.Body>Empower your community to become brand advocates using incentives like NFT collector scores, badges, and challenges. You can build a custom connection with your community in a way that you control the money, the relationship, and the experience while receiving royalties on every sale forever. </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                          <Accordion.Header>
                                                Social Impact
                                                <span></span>
                                          </Accordion.Header>
                                          <Accordion.Body>Creating a purpose-built NFT experience focused on social impact is a powerful way to engage donors and expand your impact. It allows your organization to gain additional revenue, donors, and exposure. Disrupt Studios creates web3 NFT experiences designed for social impact, allowing your organization to focus on operations and strategy.</Accordion.Body>
                                    </Accordion.Item>
                              </Accordion>
                        </div>
                  </div>

                  <div className="partners" id='partner'>
                        <div className="container">
                              <h3 className="sectitle">Partners </h3>
                        </div>
                        <div className="container">
                              <div className="slide">
                                    <article>
                                          <Slider {...settings}>
                                                <div className="pad">
                                                      <section className="box">
                                                            <div className="img">
                                                                  <img src="/assets/images/part_img1.jpg" alt="" loading="lazy" />
                                                            </div>
                                                            <div className="txt">
                                                                  <h4>Flat Out of Heels</h4>
                                                                  <p>On April 15 Flat Out of Heels unveiled its genesis NFT with Jess Marquez titled 'Ms. Flat Out' - this is an original artwork created to commemorate 10 years of Flat Out of Heels saving the soles of women everywhere! Only 100 of these NFTs were available and came with a custom hand-painted pair of Flat Out of Heels, a 12 x 12 printout of Ms. Flat Out, a 10% discount to Flat Out of Heels store, and a VIP invitation to a special event during Art Base in Miami</p>
                                                                  <PopupButton id="QTZzFXnk" className="learn_btn">
                                                                        <span>Learn More</span>
                                                                  </PopupButton>
                                                            </div>
                                                      </section>
                                                </div>
                                                <div className="pad">
                                                      <section className="box">
                                                            <div className="img">
                                                                  <img src="/assets/images/part_img2.jpg" alt="" loading="lazy" />
                                                            </div>
                                                            <div className="txt">
                                                                  <h4>Jim Jones</h4>
                                                                  <p>Badazz Bears is an NFT Project backed by Jim Jones - an American rapper, artist, television star, producer, and entrepreneur - based on original concepts from his Uncle.</p>
                                                                  <PopupButton id="QTZzFXnk" className="learn_btn">
                                                                        <span>Learn More</span>
                                                                  </PopupButton>
                                                            </div>
                                                      </section>
                                                </div>
                                               <div className="pad">
                                                      <section className="box">
                                                            <div className="img">
                                                                  <img src="/assets/images/Popcom.jpg" alt=""  loading="lazy" />
                                                            </div>
                                                            <div className="txt">
                                                                  <h4>Pop Com</h4>
                                                                  <p>We partnered with Dawn Dickson CEO of Pop Com to award her investors with an NFT. Dawn is the first female founder globally to raise over $1M secure token offering under a Reg CF of the JOBS Act.</p>
                                                                  <PopupButton id="QTZzFXnk" className="learn_btn">
                                                                        <span>Learn More</span>
                                                                  </PopupButton>
                                                            </div>
                                                      </section>
                                               </div>
                                               <div className="pad">
                                                      <section className="box">
                                                            <div className="img">
                                                                  <img src="/assets/images/part_img3.png" alt="" loading="lazy" />
                                                            </div>
                                                            <div className="txt">
                                                                  <h4>Fashion Week Columbus</h4>
                                                                  <p>We partnered with Fashion Week Columbus to help them make their first NFT drop.  Fashion Week Columbus is the flagship program of the Columbus Fashion Council, a nonprofit which produces a full week of fashion shows and fashion-related programs showcasing emerging fashion designers to media, buyers, and the incredible fashion community in Central Ohio.</p>
                                                                  <PopupButton id="QTZzFXnk" className="learn_btn">
                                                                        <span>Learn More</span>
                                                                  </PopupButton>
                                                            </div>
                                                      </section>
                                               </div>
                                          </Slider>
                                    </article>
                              </div>
                        </div>
                  </div>

                  <div className="contact_us" id="contact">
                        <div className="container">
                              <h3 className="sectitle yelclr">Contact us</h3>
                              <h4>Make your next NFT your best NFT.</h4>
                              <p>We'd love to collaborate with you to create the best NFT experience possible for your customers and fans. </p>
                              <p>Tell us a bit about your project, and let's make it happen.</p>
                              <PopupButton id="QTZzFXnk" className="contact_btn">
                                    <span>Contact us</span>
                              </PopupButton>
                        </div>
                  </div>

                  <div className="why">
                        <div className="container">
                              <h3 className="sectitle yelclr">Why Flow Blockchain</h3>
                              <p>Trusted by:</p>
                              <div className="t_logos">
                                    <ul>
                                          <li>
                                                <img src="/assets/images/trust_logo1.png" alt=""  loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo2.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo3.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo4.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo5.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo6.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo7.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo8.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo9.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo10.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo11.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo12.png" alt="" loading="lazy" />
                                          </li >
                                          <li>
                                                <img src="/assets/images/trust_logo13.png" alt="" loading="lazy" />
                                          </li >
                                    </ul>
                              </div>
                        </div>
                  </div>

                  <div className="ft">
                        <div className="container">
                              <div className="flow">
                                    <div className="blk">
                                          <div className="txt">
                                                <h4>TECHNOLOGY</h4>
                                          </div>
                                          <div className="logos">
                                                <ul className="footer-links builtwallet">
                                                      <li>
                                                            <a target="_blank" href="https://www.onflow.org/">
                                                                  <img src="/assets/images/onflow.png" alt="" className="onflowicon" loading="lazy" />
                                                                  <div className="txt">
                                                                        Flow
                                                                  </div>
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a target="_blank" href="https://www.meetdapper.com/">
                                                                  <img src="/assets/images/dapper_logo.png" alt="" className="onflowicon" loading="lazy" />
                                                                  <div className="txt">
                                                                        Dapper
                                                                  </div>
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a target="_blank" href="https://www.pinata.cloud/">
                                                                  <img src="/assets/images/pinata.png" alt="" className="onflowicon" loading="lazy" />
                                                                  <div className="txt">
                                                                        Pinata
                                                                  </div>
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a target="_blank" href="https://blaze.ws/">
                                                                  <img src="/assets/images/blaze-logo.png" alt="" className="onflowicon" loading="lazy" />
                                                                  <div className="txt">
                                                                        <span className="small">Developed at</span><br />
                                                                        <span> BLA<span className="text-danger">Z</span>E.ws</span>
                                                                  </div>
                                                            </a>
                                                      </li>
                                                </ul>
                                          </div>
                                    </div>
                              </div>
                              <div className="ft_blk">
                                    <div className="cnt">
                                          <p>© 2022 <a href="#">DisruptArt Studio</a>.</p>
                                    </div>
                                    <div className="icon">
                                          <ul>
                                                <li>
                                                      <a href="#">
                                                            <i className="fa-brands fa-twitter"></i>
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="#">
                                                            <i className="fa-brands fa-instagram"></i>
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="#">
                                                            <i className="fa-brands fa-discord"></i>
                                                      </a>
                                                </li>
                                          </ul>
                                    </div>
                              </div>
                        </div>
                  </div>

            </div>
      );
}

export { Studio }; 