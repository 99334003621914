import $ from 'jquery';



$(window).scroll(function () {
      if ($(window).scrollTop() >= 300) {
            $('.navbar').addClass('fixed-header');
            $('nav div').addClass('visible-title');
      }
      else {
            $('.navbar').removeClass('fixed-header');
            $('nav div').removeClass('visible-title');
      }
});


$(window).mousemove(function(e) {
      var ivalueX = (e.pageX * -1 / 30);
      var ivalueY = (e.pageY * -1 / 30);
      var cvalueX = (e.pageX * -1 / 40);
      var cvalueY = (e.pageY * -1 / 60);
      $('.m_icon').css('transform', 'translate3d(' + cvalueX + 'px,' + cvalueY + 'px, 0)');
      $('.m_icon').css('transform', 'translate3d(' + ivalueX + 'px,' + ivalueY + 'px, 0)');
                     
    });


$(document).ready(function() {
      $('.navbar-nav .nav-link').click(function() {
            $('.navbar-collapse').removeClass('show');
      });
});