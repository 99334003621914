import React, { Component }  from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { Studio } from './Component/Studio';
// import { Header } from './Component/Header';
// import { Footer } from './Component/Footer';

function AuthCluster() {
    return (

        <BrowserRouter >
            {/* <Header /> */}
            <Routes>
                <Route exact path="/" element={<Studio />} />
            </Routes>
            {/* <Footer /> */}
        </BrowserRouter>
    )

}

export default AuthCluster